var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('v-row',{staticClass:"pt-5 pb-5"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('span',{staticClass:"titulo-header mr-2"},[_vm._v(" Detalle de Cuota ")]),_c('span',{staticClass:"titulo-header"},[_vm._v(" #"+_vm._s(_vm.idCuota)+" ")])])],1)],1),_c('div',[_c('v-row',{staticStyle:{"padding-top":"60px"}},[_c('v-col',{class:_vm.tabReplace == 1
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"12","md":"6"},on:{"click":function($event){return _vm.tabReplaceSelect(1)}}},[_c('span',{class:_vm.tabReplace == 1
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Cuota")])]),_c('v-col',{class:_vm.tabReplace == 2
            ? 'text-center active-color'
            : 'text-center inactive-color',attrs:{"cols":"12","md":"6"},on:{"click":function($event){return _vm.tabReplaceSelect(2)}}},[_c('span',{class:_vm.tabReplace == 2
              ? 'text-header-step-active'
              : 'text-header-step-no-active'},[_vm._v(" Comisiones ")])])],1)],1),(_vm.cuota)?_c('div',{staticStyle:{"padding-top":"60px"}},[(_vm.tabReplace == 1)?_c('div',[_c('div',[_c('cuota-info-index',{attrs:{"cuota":_vm.cuota}})],1)]):_vm._e(),(_vm.tabReplace == 2)?_c('div',[_c('div',[_c('comision-info-index',{attrs:{"cuota":_vm.cuota}})],1)]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }